import React from 'react'
import Layout from '../../components/layouts/layout'

export default class GeneticsFitness extends React.Component {
  ccomponentDidMount() {
    window.location.href = "https://www.advanxhealth.com/products/dna-explorer-essential"
  }

  render() {
    return (
      <Layout>
      </Layout>
    )
  }
}
